.MainBuildingWithFooter {

    height: 100%;
    position: relative;
    height: 100%;
    width: 100%;
    color: var(--themeFontColor);

    .buildingMainContainer {
        box-sizing: border-box;
        // font-family: 'Open Sans', sans-serif;
        // min-height: calc(100vh - 40px);

        .buildingMain {
            padding: 1rem;

            .buildingHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 1rem;

                .addBuilingBtn {
                    border: transparent;
                    background-color: green;
                    color: white;
                    font-weight: bold;

                }

                .addBuilingBtn:hover {
                    border: transparent;
                    color: white;
                }

                .headleft {
                    display: flex;

                }
            }


            .buildingCard {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                box-shadow: 0 0 4px 7px rgba(0, 0, 0, .05);
                padding: 1rem;
                border-radius: 10px;
                width: 100%;
                align-items: center;
                align-self: center;


                .buildingmain {
                    /* background-color: rgb(228, 220, 199); */
                    display: flex;
                    flex-direction: column;
                    box-shadow: 0 0 4px 7px rgba(0, 0, 0, .05);
                    height: 25rem;
                    width: 17rem;
                    border-radius: 5px;
                    /* background-color: #3c3f431c; */
                    align-items: center;
                    margin: 1rem;
                    text-align: center;
                    font-size: 12px;
                    overflow: hidden;
                    font-size: 1.2rem;



                    .industoryimg {
                        height: 15rem;
                        width: 15rem;
                        margin: 1rem;
                        overflow: hidden;
                        transition: 0.5s all ease-in-out;
                        border-radius: 10px;
                        cursor: pointer;
                        object-fit: cover;
                    }

                    .buildingName {
                        cursor: pointer;
                    }

                    .industoryimg:hover {
                        transform: scale(1.05);
                    }

                    .buildingdetail {
                        cursor: pointer;
                        font-size: 14px;
                        height: 3rem;
                    }

                    .cartBtns {
                        display: flex;
                        padding-top: 1rem;
                        gap: 0.5rem;


                        .cartBtn {
                            width: 7rem;
                            border: transparent;
                            color: white;
                            font-weight: bold;
                        }

                        .editBtn {
                            background-color: green;
                        }

                        .deleteBtn {
                            background-color: red;
                        }



                        .editBtn:hover {
                            border: transparent;
                            color: white;
                        }

                        .deleteBtn:hover {
                            border: transparent;
                            color: white;
                        }
                    }
                }
            }


        }
    }

}