.LogOutMain {
    .EditBtn {
        width: 4rem;
        font-size: 0.7rem;
        height: 1.5rem;

    }
}

// .logoutbtn{
//     background-color: transparent;
//     border: 1px solid var(--backColor);
//     padding: 2px 7px;
//     border-radius: 5px;
//     margin: 0 0.5rem;
//     width: 4rem;
//     height: 1.5rem;
//     font-size: 0.7rem;
// }
// .logoutbtn:hover{
//     background-color: var(--backColor);
//     color:  white;
//     border:1px solid white;
// }